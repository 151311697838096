<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
     <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">


                  <br><label style="margin-top: 2%; margin-right: 1%;font-weight: bold;color: rgb(255, 255, 255);">Elija la
                    nueva imagen para el logo:</label>
                  <br><input type="file" style="margin-top: 1%;margin-bottom: 2vh" (change)="changeFileMenu($event)">

                  <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                    *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

                  <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

                </div>

  <section id="blog" class="blog" style="margin-top: 50px;">
    <body class="align">

      <div class="login">

        <header class="login__header">
          <h2><svg class="icon">
              <use xlink:href="#icon-lock" />
            </svg>Sign In</h2>
        </header>

        <form action="#" class="login__form" method="POST">

          <div>
            <label for="email">E-mail address</label>
            <input type="email" id="email" name="email" placeholder="mail@address.com">
          </div>

          <div>
            <label for="password">Password</label>
            <input type="password" id="password" name="password" placeholder="password">
          </div>

          <div>
            <input class="button" type="submit" value="Sign In">
          </div>

        </form>

      </div>

      <svg xmlns="http://www.w3.org/2000/svg" class="icons">

        <symbol id="icon-lock" viewBox="0 0 448 512">
          <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
        </symbol>

      </svg>

    </body>

    <!-- https://fontawesome.com/icons/lock?style=solid -->

  </section>


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
