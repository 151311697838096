import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-lgcg-conac',
  templateUrl: './lgcg-conac.component.html',
  styleUrls: ['./lgcg-conac.component.scss']
})
export class LgcgConacComponent {
  docs: any;
  boton: any;

  eliminarForm: any = {
    ID: new FormControl(''),
    ESTATUS: 0
  };

  constructor(private router: Router, private alerts: SweetAlertService, private api: Fraccion1Service, private general: GeneralService) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  verDocumentos(carpeta: any, trimestre: any,ano:any) {
    console.log(carpeta, trimestre);
    this.docs = []
    this.api.obtenerf13(0, 0, carpeta, trimestre,ano).subscribe(res => {
      this.docs = res.body;
      console.log(this.docs);
    })
  }

  subirDocumento() {
    this.router.navigate(['admin/lgcg-conac/subir-documento']);
  }

  eliminar(ID: any) {
    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {
              console.log(ID);
            })
          this.alerts.alertaRealizado().then((res: any) => {

          })
        }
      })
  }
}

