import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subir-documentos',
  templateUrl: './subir-documentos.component.html',
  styleUrls: ['./subir-documentos.component.scss']
})
export class SubirDocumentosComponent {
  boton: any;

  documentoForm = new FormGroup({
    ARTICULO: new FormControl(''),
    FRACCION: new FormControl(''),
    INCISO: new FormControl(''),
    NOMBRE: new FormControl(''),
    ENLACE: new FormControl(''),
    ANO: new FormControl(''),
    SUBSIDIO: new FormControl(''),
    AREA: new FormControl(''),
    CARPETA: new FormControl(''),
    QUINCENA: new FormControl(''),
    MES: new FormControl(''),
    ANOTACIONES: new FormControl(''),
    ESTATUS: new FormControl('')
  })

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;

  archivos: string[] = [];

  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage, private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.boton = 1;
    } else {
      this.router.navigate(['admin/informacion-financiera']);
    }

    this.documentoForm.setValue({
      'ARTICULO': '8',
      'FRACCION': '0',
      'INCISO': '0',
      'NOMBRE': '',
      'ENLACE': '',
      'SUBSIDIO': '0',
      'ANO': '',
      'ESTATUS': '0',
      'AREA': '',
      'CARPETA': '',
      'MES': '0',
      'QUINCENA': '0',
      'ANOTACIONES': ' ',
    });
  }

  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })

  async contarArchivos(event: any) {
    let cont = event.target.files.length;
    console.log(cont);

    for (let i = 0; i < cont; i++) {
      console.log(i);
      this.changeFileMenu(event, i);
      let file = event.target.files[i];
      console.log(file.name);
      this.archivos[i] = file.name;
    }

    console.log(this.archivos);

  }

  async changeFileMenu(event: any, index: any) {

    let file = event.target.files[index];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase

      const name = '- Autlan';

      let path = 'lgcgconac';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + this.archivos[index]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.documentoForm.value.ENLACE = file;
          console.log(this.documentoForm.value.ENLACE);
          this.documentoForm.value.NOMBRE = this.archivos[index];
          console.log(this.documentoForm.value.NOMBRE);
          this.cargar();

        }).catch(error => { console.log(error) });

      })
    })
  }

  cargar() {

    console.log(this.documentoForm.value.NOMBRE);
    console.log(this.documentoForm.value);

    if (this.documentoForm.value.CARPETA !== '' &&
      this.documentoForm.value.AREA !== '' &&
      this.documentoForm.value.NOMBRE !== '' &&
      this.documentoForm.value.ENLACE !== '') {
      console.log(this.documentoForm.value);
      this.api.nuevoDoc(this.documentoForm.value).subscribe(res => {
        console.log('documento subido');
        this.alerts.alertaRealizadoAsistencia('Completado', 'El documento se ha cargado con exito');
      })
    } else {
      this.alerts.alertaError('Error', 'Llene los campos necesarios');
    }
  }
}

