<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <section id="blog" class="blog" style="margin-top: 120px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Cargar documento:</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; ">Seleccione la carpeta:</label>
            <select formControlName="AREA" style="border-radius: 1vh;">
              <option value="80">Iniciativas y Proyectos</option>
              <option value="81">Ley de Ingresos y Presupuestos de Egresos</option>
              <option value="82">Información Contable</option>
              <option value="83">Información Presupuestaria</option>
              <option value="84">Inventarios</option>
              <option value="85">Ejercicio Presupuestario</option>
              <option value="86">Cuenta Pública</option>
              <option value="87">Disciplina Financiera</option>
            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Seleccione el trimestre:</label>
            <select formControlName="CARPETA" style="border-radius: 1vh;">
              <option value="201">Primer Trimestre</option>
              <option value="202">Segundo Trimestre</option>
              <option value="203">Tercer Trimestre</option>
              <option value="204">Cuarto Trimestre</option>
            </select>


            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Seleccione el Año:</label>
            <select formControlName="ANO" style="border-radius: 1vh;">
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>

            </select>


            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el
              documento a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos($event)" formControlName="NOMBRE"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>
            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

          </form>
        </div>
      </div>
    </div>
  </section>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
